.container {
  @apply flex justify-between w-full pt-0 mx-auto;
}

.account {
  @apply w-full;
  @screen md {
    width: 54%;
  }
}
